/*TODO: file has some issues with the fonts loading, so we would need to move fonts to global styles*/
@font-face {
  font-family: 'Glyphicons Halflings';
  font-display: swap;

  src: local('Glyphicons Halflings'), local('Glyphicons Halflings'),
    url('../fonts/glyphicons-halflings-regular.woff2') format('woff2'),
    url('../fonts/glyphicons-halflings-regular.woff') format('woff');
}

@font-face {
  font-family: 'Montserrat';
  font-weight: normal;
  font-style: normal;
  font-display: swap;

  src: local('Montserrat Regular'), local('Montserrat Regular'),
    url('../fonts/Montserrat-Regular.woff2') format('woff2'),
    url('../fonts/Montserrat-Regular.woff') format('woff');
}

@font-face {
  font-family: 'Montserrat';
  font-weight: normal;
  font-style: italic;
  font-display: swap;

  src: local('Montserrat Italic'), local('Montserrat Italic'),
    url('../fonts/Montserrat-Italic.woff2') format('woff2'),
    url('../fonts/Montserrat-Italic.woff') format('woff');
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 500;
  font-style: normal;
  font-display: swap;

  src: local('Montserrat Medium'), local('Montserrat Medium'),
    url('../fonts/Montserrat-Medium.woff2') format('woff2'),
    url('../fonts/Montserrat-Medium.woff') format('woff');
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 500;
  font-style: italic;
  font-display: swap;

  src: local('Montserrat MediumItalic'), local('Montserrat MediumItalic'),
    url('../fonts/Montserrat-MediumItalic.woff2') format('woff2'),
    url('../fonts/Montserrat-MediumItalic.woff') format('woff');
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 600;
  font-style: normal;
  font-display: swap;

  src: local('Montserrat SemiBold'), local('Montserrat SemiBold'),
    url('../fonts/Montserrat-SemiBold.woff2') format('woff2'),
    url('../fonts/Montserrat-SemiBold.woff') format('woff');
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 600;
  font-style: italic;
  font-display: swap;

  src: local('Montserrat SemiBoldItalic'), local('Montserrat SemiBoldItalic'),
    url('../fonts/Montserrat-SemiBoldItalic.woff2') format('woff2'),
    url('../fonts/Montserrat-SemiBoldItalic.woff') format('woff');
}

html,
body,
#root {
  height: 100%;
}

body {
  margin: 0;

  font-family: 'Montserrat', 'Helvetica', 'Arial', sans-serif;

  background-color: #cccdcd;

  font-size: 13px;
}
